import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Row, Col, Typography } from 'antd'
import { Button, Icon } from 'witmeg-ui-system';
import IMAGE_PLACEHOLDER from '../../../../../assets/Images/image-placeholder.png';
import { getCurrencyFormat, getRestaurantMenuProductPrice } from '../../../utilities/helper';
import { getProductItemDataForPopup } from '../../../utilities/restaurantMenuUtil';
import '../../../utilities/stringOverride';
import UpdateCartPopup from '../Common/UpdateCartPopup';
import ShowMoreText from 'react-show-more-text';

/*
  Layout: 1
    Shows menu items in one column with images
*/
export default function OneColumnWithImageLayout({ isPopup = false }) {

  const [addToCartModalState, setAddToCartModalState] = useState({ data: null, visible: false });

  const { orderSettings, menuData: { selectedMainMenuData, uiCustomizations }, currency } = useSelector((state) => state.resturantData);
  const selectedOrderType = orderSettings.urlOrder?.orderType ?? orderSettings.settingsDefaultOrder?.orderType;

  const currencyFormat = getCurrencyFormat(currency);

  const onProductItemClick = async (data) => {
    const _productData = await getProductItemDataForPopup(data);
    if (_productData) setAddToCartModalState({ data: _productData, visible: true });
  };

  const onPopupClose = () => setAddToCartModalState({ data: null, visible: false });

  return (
    <Row>
      {selectedMainMenuData && selectedMainMenuData.SubMenu && selectedMainMenuData.SubMenu.length > 0 && selectedMainMenuData.SubMenu.map((data, index) => {
        return (
          <>
            {
              uiCustomizations.subMenu.settings.subMenuEnabled &&
              <label className='eco-layout-sub-category' style={{ ...uiCustomizations.subMenu.style }}>
                {data?.SubMenuName.toCapitalFirst()}
              </label>
            }

            {data.MenuProductData.map((data, index) => {
              const productPrice = getRestaurantMenuProductPrice(data, selectedOrderType);
              const isSelected = (addToCartModalState.data && addToCartModalState.data.id === data.ID) ? true : false;
              const productImage = data?.ProductData?.ImageURL && data?.ProductData?.ImageURL.POS && data?.ProductData?.ImageURL.POS.length > 0 ? data?.ProductData?.ImageURL.POS[0] : IMAGE_PLACEHOLDER;
              const productDescription = data?.ProductData?.ItemDescription?.Translations?.en


              return (
                <>
                  <Col span={24} style={{ padding: '10px', }}>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '8px', height: '16rem', opacity: '0.8' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5rem 1rem' }}>
                        <div style={{ padding: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                          <div>
                            <Typography type="title" level={4} pattern="bold" style={{ fontWeight: 'bold', fontSize: '16px' }}>{data.ProductName.toCapitalFirst()}</Typography>
                          </div>
                          <div>
                            <Typography type="title" level={5} style={{ padding: '5px 0', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>{productDescription}</Typography>
                          </div>
                        </div>
                        <img src={productImage} style={{ height: '10rem', width: '10rem', padding: '5px' }} />
                      </div>
                      <div style={{ padding: '0.5rem 1.5rem', justifyContent: 'space-between', display: 'flex' }}>
                        <Typography type="title" level={5} pattern="bold">{currencyFormat(productPrice)}</Typography>
                        <Button variant="primary"
                          onClick={() => onProductItemClick(data)}>
                          <label>Add to Cart</label>
                          {/* <label className='mobile-but'><Icon name="plus" size={24} /></label> */}
                        </Button>
                      </div>
                    </div>
                  </Col>
                  {/* <div
                    className={`product-row layout-product-item ${isSelected ? 'product-row-active' : ''}`}
                    style={{ ...uiCustomizations.productItem.itemSpaceBetween }} >
                    <div className='eco-product-left-side'>
                      <div style={{ backgroundImage: `url(${productImage})` }} className="product-image"></div>
                      <div>
                        <div className="product-name-label">
                          <label style={uiCustomizations.productItem.name}>
                            {data.ProductName.toCapitalFirst()}
                          </label>
                        </div>
                        {
                          data.ProductData?.ServicePriceInfo?.Price !== null &&
                          <label className='eco-product-price-label' style={uiCustomizations.productItem.name}>{currencyFormat(productPrice)}</label>
                        }
                        <div className="product-description">
                          <label style={uiCustomizations.productItem.description}>
                            <ShowMoreText
                              lines={2}
                              more="More"
                              less="Less"
                              expanded={false}
                              width={360}
                              truncatedEndingComponent={"..."}>
                              {data.ProductData.ItemDescription.Translations.en.toCapitalFirst()}
                            </ShowMoreText>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='eco-product-right-side'>
                      {
                        data.ProductData?.ServicePriceInfo?.Price !== null &&
                        <label className='eco-product-price-label' style={uiCustomizations.productItem.name}>{currencyFormat(productPrice)}</label>
                      }
                      {window.location.hostname !== "kottuking.witmeg.net" ?
                        <Button variant="primary" className="eco-add-to-cart-label"
                          onClick={() => onProductItemClick(data)}>
                          <label className='desktop-but'>Add to Cart</label>
                          <label className='mobile-but'><Icon name="plus" size={24} /></label>
                        </Button>
                        : null}
                    </div>
                  </div> */}
                </>
              );
            })}
          </>
        );
      })}
      {
        addToCartModalState.data && addToCartModalState.data.ProductName !== "" && addToCartModalState.visible &&
        <UpdateCartPopup showPopup={addToCartModalState.visible} data={addToCartModalState.data} onPopupClose={onPopupClose} />
      }
    </Row>
  );
}
